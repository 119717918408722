import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109')
];

export const server_loads = [];

export const dictionary = {
		"/(marketing)": [36,[4]],
		"/(dash)/apps/[workspace]/[environment]": [22,[2],[3]],
		"/(dash)/apps/[workspace]/[environment]/deployed/[name]": [24,[2],[3]],
		"/(dash)/apps/[workspace]/[environment]/[appId]": [23,[2],[3]],
		"/(marketing)/articles": [37,[4]],
		"/(signin)/authorize": [51,[7]],
		"/(marketing)/blog": [38,[4,5]],
		"/(marketing)/blog/[slug]": [39,[4,5]],
		"/careers": [61],
		"/(marketing)/company": [40,[4]],
		"/(signin)/credit-card-gate": [52,[7]],
		"/docs": [62,[8],[9]],
		"/docs/examples": [64,[8,10],[9]],
		"/docs/examples/[...id]": [65,[8,10],[9]],
		"/docs/guide/[...id]": [66,[8,11],[9]],
		"/docs/reference/changelog": [68,[8,12],[9]],
		"/docs/reference/cli/[...id]": [69,[8,12],[9]],
		"/docs/reference/[...id]": [67,[8,12],[9]],
		"/docs/[...fallback]": [63,[8],[9]],
		"/examples": [70],
		"/home": [71],
		"/integrations/[slug]/configure": [72],
		"/internal": [73,[13]],
		"/internal/accounts": [74,[13,14]],
		"/internal/accounts/[workspace]": [75,[13,14]],
		"/internal/batch": [76,[13]],
		"/internal/design": [77,[13,15]],
		"/internal/design/components": [78,[13,15]],
		"/internal/design/graphics": [79,[13,15]],
		"/internal/design/typography": [80,[13,15]],
		"/internal/logs": [81,[13]],
		"/internal/lookup": [82,[13,16]],
		"/internal/lookup/[id]": [83,[13,16]],
		"/internal/queues": [84,[13]],
		"/internal/resources": [85,[13]],
		"/internal/resources/fullscreen": [86],
		"/internal/users": [87,[13]],
		"/internal/users/[userId]": [88,[13]],
		"/internal/workers": [89,[13]],
		"/(marketing)/legal/privacy-policy": [41,[4]],
		"/(marketing)/legal/terms": [42,[4]],
		"/(signin)/login": [53,[7]],
		"/(signin)/login/sso": [54,[7]],
		"/(dash)/logs/[workspace]/[environment]": [25,[2],[3]],
		"/(dash)/logs/[workspace]/[environment]/call/[functionCallId]": [27,[2],[3]],
		"/(dash)/logs/[workspace]/[environment]/[appId]": [26,[2],[3]],
		"/(signin)/onboarding-form": [55,[7]],
		"/playground": [90,[17]],
		"/playground/[slug]": [91,[17]],
		"/(marketing)/pricing": [43,[4]],
		"/(dash)/sandboxes/[workspace]/[environment]": [28,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]": [29,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/create": [30,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/edit/[name]": [31,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/usage/[name]": [32,[2],[3]],
		"/settings": [92,[18]],
		"/settings/[[workspace]]/audit-logs": [93,[18,19]],
		"/settings/[[workspace]]/domains": [94,[18,19]],
		"/settings/[[workspace]]/image-config": [95,[18,19]],
		"/settings/[[workspace]]/member-tokens": [96,[18,19]],
		"/settings/[[workspace]]/members": [97,[18,19]],
		"/settings/[[workspace]]/metrics": [98,[18,19]],
		"/settings/notifications": [105,[18]],
		"/settings/[[workspace]]/plans": [99,[18,19]],
		"/settings/profile": [106,[18]],
		"/settings/[[workspace]]/proxy-ips": [100,[18,19]],
		"/settings/[[workspace]]/slack-integration": [101,[18,19]],
		"/settings/[[workspace]]/tokens": [102,[18,19]],
		"/settings/[[workspace]]/usage": [103,[18,19]],
		"/settings/[[workspace]]/workspace-management": [104,[18,19]],
		"/settings/workspaces": [107,[18]],
		"/(signin)/signup": [56,[7]],
		"/(dash)/slack-link-workspace": [33,[2],[3]],
		"/slack": [108],
		"/(dash)/storage/[workspace]/[environment]": [34,[2],[3]],
		"/(dash)/storage/[workspace]/[environment]/[name]/[...dirName]": [35,[2],[3]],
		"/token-flow/[tokenFlowId]": [109,[20]],
		"/(marketing)/use-cases": [44,[4,6]],
		"/(marketing)/use-cases/audio": [45,[4,6]],
		"/(marketing)/use-cases/fine-tuning": [46,[4,6]],
		"/(marketing)/use-cases/image-video-3d": [47,[4,6]],
		"/(marketing)/use-cases/job-queues": [48,[4,6]],
		"/(marketing)/use-cases/language-models": [49,[4,6]],
		"/(marketing)/use-cases/sandboxes": [50,[4,6]],
		"/[workspace=workspace]/[environment]/apps/[...rest]": [57],
		"/[workspace=workspace]/[environment]/logs/[...rest]": [58],
		"/[workspace=workspace]/[environment]/secrets/[...rest]": [59],
		"/[workspace=workspace]/[environment]/storage/[...rest]": [60],
		"/(dash)/[fallback=dash]/[...rest]": [21,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';